<template>
    <section class="bg-pink-light p-20 text-gray-800">
      <div class="w-full max-w-4xl mx-auto text-center">
        <h3 class="text-4xl font-bold mb-12 text-pink-500">Voucher & Cartão de Fidelidade</h3>
  
        <!-- Cartões de Voucher e Cartão de Fidelidade -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          
          <!-- Cartão de Voucher -->
          <div class="benefit-card" data-aos="fade-up" @click="showModal(voucherImage)">
            <img :src="voucherImage" alt="Voucher de Massagem" class="rounded-t-lg w-full h-40 object-cover mb-4 cursor-pointer">
            <h4 class="text-2xl font-semibold mb-2">Voucher</h4>
            <p class="text-gray-700">
              Ofereça um momento de relaxamento com nossos vouchers de massagem.
            </p>
          </div>
  
          <!-- Cartão de Cartão de Fidelidade -->
          <div class="benefit-card" data-aos="fade-up" @click="showModal(fidelityCardImage)">
            <img :src="fidelityCardImage" alt="Cartão de Fidelidade" class="rounded-t-lg w-full h-40 object-cover mb-4 cursor-pointer">
            <h4 class="text-2xl font-semibold mb-2">Cartão de Fidelidade</h4>
            <p class="text-gray-700">
              Ganhe descontos e benefícios com nosso cartão de fidelidade.
            </p>
          </div>
  
        </div>
        
        <!-- Modal para exibir imagem em tamanho maior -->
        <div v-if="isModalOpen" class="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div class="modal-content bg-white p-4 rounded-lg relative max-w-lg w-full">
            <button @click="isModalOpen = false" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-xl">&times;</button>
            <img :src="currentImage" alt="Imagem Ampliada" class="w-full h-auto rounded-lg">
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import AOS from 'aos';
  import 'aos/dist/aos.css';
  
  // Importando imagens
  import voucherImage from '@/assets/images/voucher.jpg';
  import fidelityCardImage from '@/assets/images/fidelity-card.jpg';
  
  export default {
    name: 'VoucherSection',
    data() {
      return {
        voucherImage,
        fidelityCardImage,
        isModalOpen: false,
        currentImage: null,
      };
    },
    methods: {
      showModal(image) {
        this.currentImage = image;
        this.isModalOpen = true;
      }
    },
    mounted() {
      AOS.init({
        duration: 800,
        easing: 'ease-in-out',
        once: true
      });
    }
  }
  </script>
  
  <style scoped>
  /* Fundo rosa claro */
  .bg-pink-light {
    background-color: #fdf2f8;
    padding: 5rem 0;
    border-radius: 12px;
  }
  
  /* Título em rosa */
  .text-pink-500 {
    color: #e09cb9;
  }
  
  /* Estilo dos cartões de benefícios */
  .benefit-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    cursor: pointer;
  }
  .benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Estilo da imagem */
  .benefit-card img {
    border-radius: 8px;
    height: 160px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  /* Modal overlay */
  .modal-overlay {
    z-index: 50;
  }
  
  /* Modal conteúdo */
  .modal-content {
    z-index: 100;
  }
  </style>
  