<template>
  <div>
    <!-- Seção de Logotipo, Título e Subtítulo -->
    <div ref="logoHeader" class="logo-header text-white p-4 flex justify-center items-center">
      <div class="container mx-auto flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
        <!-- Logotipo -->
        <img src="@/assets/icons/logo.jpeg" alt="Logotipo" class="w-16 h-16 md:w-20 md:h-20 object-cover" />
        
        <!-- Título e Subtítulo -->
        <div class="text-center md:text-left">
          <h1 class="text-2xl font-bold">Corpo e Mente</h1>
          <p class="text-sm md:text-base">Bem-estar e equilíbrio para sua saúde</p>
        </div>
      </div>
    </div>

    <!-- Cabeçalho Principal (sticky) -->
    <header class="sticky-header text-accent p-4 shadow-md">
      <div class="container mx-auto flex justify-between items-center">
        <!-- Ícones de Contato e Redes Sociais no Lado Esquerdo -->
        <div class="flex space-x-4">
          <a href="mailto:anacletomarina93@gmail.com" class="text-white hover:text-pink-light">
            <i class="fas fa-envelope"></i>
          </a>
          <a href="tel:+351918926359" class="text-white hover:text-pink-light">
            <i class="fas fa-phone"></i>
          </a>
          <a href="https://www.instagram.com/corpoemente20241" target="_blank" class="text-white hover:text-pink-light">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://wa.me/1234567890" target="_blank" class="text-white hover:text-pink-light">
            <i class="fab fa-whatsapp"></i>
          </a>
        </div>

        <!-- Seleção de Idioma -->
        <div class="language-selector flex items-center space-x-4">
          <img src="../assets/images/flag-pt.png" alt="Português" @click="changeLanguage('pt')" class="w-8 cursor-pointer">
          <img src="../assets/images/flag-en.png" alt="English" @click="changeLanguage('en')" class="w-8 cursor-pointer">

        </div>

        <!-- Links de Navegação (desktop) -->
        <nav class="hidden md:flex space-x-6">
          <a @click="scrollToSection('inicio')" class="nav-link cursor-pointer">{{ $t('nav.home') }}</a>
          <a @click="scrollToSection('portfolio')" class="nav-link cursor-pointer">{{ $t('nav.about') }}</a>
          <a @click="scrollToSection('PageMassagem')" class="nav-link cursor-pointer">{{ $t('nav.massages') }}</a>
          <a @click="scrollToSection('services')" class="nav-link cursor-pointer">{{ $t('nav.pricing') }}</a>
          <a @click="scrollToSection('Footer')" class="nav-link cursor-pointer">{{ $t('nav.contact') }}</a>
        </nav>

        <!-- Exibição do Horário no Lado Direito -->
        <div class="hidden md:block text-white font-semibold">
          {{ currentTime }}
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTime: new Date().toLocaleTimeString(), // Horário inicial
    };
  },
  created() {
    setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const savedLanguage = localStorage.getItem('language') || 'pt';
    this.$i18n.locale = savedLanguage;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const logoHeader = this.$refs.logoHeader;
      logoHeader.style.display = window.scrollY > 100 ? "none" : "flex";
    },
    scrollToSection(id) {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang); // Salva a preferência
    }
  }
};
</script>

<style scoped>
.logo-header {
  background-color: #e09cb9;
  transition: display 0.5s ease;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #e09cb9;
}

.text-accent {
  color: #ffffff;
}

.text-pink-light {
  color: #f7c1d3;
}

.nav-link {
  color: #ffffff;
  transition: color 0.3s ease;
  text-decoration: none;
}

.nav-link:hover {
  color: #f7c1d3;
}

/* Estilos do Navbar */
.language-selector img {
  transition: transform 0.3s ease;
}
.language-selector img:hover {
  transform: scale(1.1);
}
</style>
