<template>
  <section class="bg-white p-20 flex justify-center text-gray-800">
    <div class="w-full max-w-6xl relative">
      <h3 class="text-4xl font-bold mb-12 text-center text-pink-500">Nosso Espaço</h3>

      <!-- Carrossel de Fotos -->
      <div class="carousel-wrapper relative overflow-hidden">
        <!-- Setas de navegação -->
        <button @click="prevSlide" class="carousel-arrow left-0">
          &#10094; <!-- Seta esquerda -->
        </button>
        
        <div class="carousel-container flex transition-transform duration-500" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
          <div
            class="carousel-card relative"
            v-for="(image, index) in images"
            :key="index"
            data-aos="fade-up"
          >
            <!-- Fundo decorativo desfocado -->
            <div
              class="background-blur"
              :style="{ backgroundImage: `url(${image.src})` }"
            ></div>

            <!-- Imagem principal -->
            <img
              :src="image.src"
              :alt="image.alt"
              class="rounded-lg w-auto max-h-full object-contain relative z-10"
            />
          </div>
        </div>

        <button @click="nextSlide" class="carousel-arrow right-0">
          &#10095; <!-- Seta direita -->
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

// Importando as imagens diretamente
import Ambiente1 from '@/assets/images/ambiente1.jpg';
import Ambiente2 from '@/assets/images/ambiente2.jpg';
import Ambiente3 from '@/assets/images/ambiente3.jpg';
import Ambiente4 from '@/assets/images/ambiente4.jpg';
import Ambiente6 from '@/assets/images/ambiente6.jpg';
import Ambiente7 from '@/assets/images/ambiente7.jpg';

export default {
  name: 'PhotoCarousel',
  data() {
    return {
      images: [
        { src: Ambiente1, alt: 'Ambiente 1' },
        { src: Ambiente2, alt: 'Ambiente 2' },
        { src: Ambiente3, alt: 'Ambiente 3' },
        { src: Ambiente4, alt: 'Ambiente 4' },
        { src: Ambiente6, alt: 'Ambiente 6' },
        { src: Ambiente7, alt: 'Ambiente 7' },
      ],
      currentSlide: 0,
    };
  },
  methods: {
    nextSlide() {
      // Avança para o próximo slide, ou volta ao início
      this.currentSlide = (this.currentSlide + 1) % this.images.length;
    },
    prevSlide() {
      // Volta para o slide anterior, ou para o último slide
      this.currentSlide = (this.currentSlide - 1 + this.images.length) % this.images.length;
    },
  },
  mounted() {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true
    });
  }
}
</script>

<style scoped>
/* Fundo branco */
.bg-white {
  background-color: #ffffff;
  padding: 5rem 0;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

/* Título em rosa */
.text-pink-500 {
  color: #e09cb9;
}

/* Contêiner do carrossel com rolagem controlada */
.carousel-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Container que se move para exibir os slides */
.carousel-container {
  display: flex;
}

/* Estilo dos cartões do carrossel */
.carousel-card {
  min-width: 100%;
  height: 600px; /* Altura maior para imagens de diversos tamanhos */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 1rem;
}

/* Fundo desfocado atrás da imagem */
.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(20px);
  opacity: 1;
  z-index: 5;
}

/* Ajuste da imagem principal */
.carousel-card img {
  max-height: 100%; /* Ajuste para adaptar imagens maiores */
  max-width: 120%;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 10;
}
.carousel-card:hover img {
  transform: scale(1.05);
  opacity: 0.95;
}

/* Estilo das setas de navegação */
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
  transition: background 0.3s ease;
}
.carousel-arrow:hover {
  background: rgba(0, 0, 0, 0.7);
}
.carousel-arrow.left-0 {
  left: 10px;
}
.carousel-arrow.right-0 {
  right: 10px;
}
</style>
