<template>
  <div class="flex flex-col min-h-screen">
    <PageHeader class="mb-12" />
    <div class="container mx-auto p-8 flex-grow">
      <h1 class="text-3xl font-bold mb-4">Serviço de Websites</h1>
      <p>Descrição detalhada sobre o serviço de desenvolvimento de websites.</p>
      
      <!-- Botões de navegação -->
      <div class="mt-8">
        <router-link to="/seo" class="btn-primary mr-4">SEO</router-link>
        <router-link to="/traffic" class="btn-primary">Tráfego Pago</router-link>
      </div>
    </div>
    <PageFooter class="mt-auto" />
  </div>
</template>

<script>
import PageHeader from './PageHeader.vue';
import PageFooter from './PageFooter.vue';

export default {
  components: {
    PageHeader,
    PageFooter
  },
  name: 'WebsitesPage'
};
</script>

<style scoped>
/* Botão de navegação estilizado */
.btn-primary {
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.btn-primary:hover {
  background-color: #333333;
}
</style>
