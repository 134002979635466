<template>
  <div id="app">
    <!-- Exibe o carregamento se `showLoading` for true -->
    <LoadingOverlay v-if="showLoading" />

    <!-- Exibe o conteúdo do app e o banner de cookies quando o carregamento termina -->
    <div v-else>
      <cookie-banner v-if="showCookieBanner" @accept="acceptCookies" />
      <router-view />
    </div>
  </div>
</template>

<script>
import LoadingOverlay from './components/LoadingOverlay.vue';
import CookieBanner from './components/CookieBanner.vue';


export default {
  components: {
    LoadingOverlay,
    CookieBanner,
  },
  data() {
    return {
      showLoading: true,
      showCookieBanner: false, // Controla a exibição do banner de cookies
    };
  },
  mounted() {
    // Exibe o overlay de carregamento por 5 segundos
    setTimeout(() => {
      this.showLoading = false;
      // Mostra o banner de cookies depois do carregamento, se o usuário ainda não tiver aceito
      if (!this.getCookie('cookiesAccepted')) {
        this.showCookieBanner = true;
      }
    }, 5000);
  },
  methods: {
    acceptCookies() {
      this.setCookie('cookiesAccepted', 'true', 365);
      this.showCookieBanner = false;
      this.initializeAnalytics(); // Inicializa o Google Analytics após o consentimento
    },
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
    },
    getCookie(name) {
      const cookieArr = document.cookie.split(';');
      for (let cookie of cookieArr) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
          return cookieValue;
        }
      }
      return null;
    },
    initializeAnalytics() {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-W19JE5Y0FM';
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-W19JE5Y0FM');
      };
    },
  },
  metaInfo: {
    title: 'Corpo & Mente',
    meta: [
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
      { name: 'description', content: 'Oferecemos serviços de massagem terapêutica e relaxante para promover o bem-estar e a saúde do corpo.' },
      { name: 'keywords', content: 'massagem, bem-estar, relaxamento, saúde, terapêutica, Peniche, massagem near me, massagem in meiner nahe, massage a proximite, massagem relaxante, Portugal' },
      { name: 'author', content: 'Corpo & Mente' },
      { property: 'og:title', content: 'Massagens e Bem-estar - Corpo & Mente' },
      { property: 'og:description', content: 'Oferecemos serviços de massagem terapêutica e relaxante para promover o bem-estar e a saúde do corpo.' },
      { property: 'og:image', content: '/img/logo.jpeg' },
      { property: 'og:url', content: 'http://www.corpomente.pt' },
      { property: 'og:type', content: 'website' },
      { name: 'geo.region', content: 'PT-10' },
      { name: 'geo.placename', content: 'Peniche' },
      { name: 'geo.position', content: '39.3552;-9.3816' },
      { name: 'ICBM', content: '39.3552, -9.3816' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Massagens e Bem-estar - Corpo & Mente' },
      { name: 'twitter:description', content: 'Oferecemos serviços de massagem terapêutica e relaxante para promover o bem-estar e a saúde do corpo.' },
      { name: 'twitter:image', content: '/img/logo.jpeg' }
    ],
    script: [
      {
        src: 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css',
        rel: 'stylesheet'
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css',
        rel: 'stylesheet'
      },
      {
        async: true,
        innerHTML: `
          var _paq = window._paq = window._paq || [];
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://queridinhasdacintia.matomo.cloud/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='https://cdn.matomo.cloud/queridinhasdacintia.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
          })();
        `,
        type: 'text/javascript',
        charset: 'utf-8'
      }
    ],
    __dangerouslyDisableSanitizersByTagID: {
      matomo: ['innerHTML']
    }
  }
};
</script>

<style scoped>
/* Adicione estilos personalizados se necessário */
</style>
