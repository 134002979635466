<template>
  <div>
    <PageHeader />
    <HeroSection id="inicio" class="mb-8" />
    <PortfolioSection id="portfolio" class="mb-8" />
    <PageMassagem id="PageMassagem" class="mb-8" />
    <ServicesSection id="services" class="mb-8" />
    <PageVoucher id="Voucher" class="mb-8" />
    <PageReweu id="Reweu" class="mb-8" />
    <PageFooter id="Footer" class="mt-8" />

    <!-- Ícone do WhatsApp para abrir o chat modal -->
    <div class="whatsapp-icon-container">
      <button @click="openChat" class="whatsapp-icon">
        <i class="fab fa-whatsapp"></i>
      </button>
    </div>

    <!-- Modal de Chat ao estilo WhatsApp -->
    <transition name="fade">
      <div v-if="showChatModal" class="chat-modal-overlay">
        <div class="chat-modal">
          <div class="chat-header">
            <img src="@/assets/icons/minha-foto.jpg" alt="Minha foto" class="profile-img" />
            <span>Corpo e Mente - Marina</span>
            <button @click="closeChat" class="close-button">&times;</button>
          </div>
          <div class="chat-body">
            <div class="message received">
              <p>Olá! Chamo-me Marina. Gostaria de saber o seu nome e telefone para agendar sua massagem.</p>
            </div>
            <div v-if="clientName && clientPhone" class="message sent">
              <p>{{ `Olá, sou ${clientName} e meu telefone é ${clientPhone}. Gostaria de marcar uma massagem.` }}</p>
            </div>
            <div class="input-container">
              <input
                v-model="clientName"
                type="text"
                placeholder="Seu nome"
                class="chat-input"
              />
              <input
                v-model="clientPhone"
                type="tel"
                placeholder="Seu telefone"
                class="chat-input"
              />
            </div>
          </div>
          <div class="chat-footer">
            <button @click="sendMessage" class="send-button">Enviar</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PageHeader from './PageHeader.vue';
import HeroSection from './HeroSection.vue';
import PortfolioSection from './PortfolioSection.vue';
import ServicesSection from './ServicesSection.vue';
import PageFooter from './PageFooter.vue';
import PageMassagem from './PageMassagem.vue';
import PageVoucher from './PageVoucher.vue';
import PageReweu from './PageReweu.vue';

export default {
  components: {
    PageHeader,
    HeroSection,
    PortfolioSection,
    ServicesSection,
    PageMassagem,
    PageVoucher,
    PageReweu,
    PageFooter
  },
  data() {
    return {
      showChatModal: false,
      clientName: '',
      clientPhone: ''
    };
  },
  mounted() {
    // Abre o chat automaticamente após 10 segundos
    setTimeout(() => {
      this.openChat();
    }, 10000);
  },
  methods: {
    openChat() {
      this.showChatModal = true;
    },
    closeChat() {
      this.showChatModal = false;
    },
    sendMessage() {
      const mensagem = `Olá, sou ${this.clientName} e meu telefone é ${this.clientPhone}. Gostaria de marcar uma massagem.`;
      const numero = "+351918926359";
      const url = `https://wa.me/${numero}?text=${encodeURIComponent(mensagem)}`;
      window.open(url, "_blank");
      this.closeChat();
    }
  }
};
</script>

<style scoped>
/* Animação de transição para o modal */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Ícone do WhatsApp */
.whatsapp-icon-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.whatsapp-icon {
  background-color: #25D366;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

/* Estilos do modal de chat */
.chat-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.chat-modal {
  background: #ffffff;
  width: 320px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
.chat-header {
  background: #e09cb9;
  color: white;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
}
.profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.chat-body {
  padding: 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.message {
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 14px;
}
.received {
  background: #f1f1f1;
  align-self: flex-start;
}
.sent {
  background: #d1ffe7;
  align-self: flex-end;
}
.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.chat-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}
.chat-footer {
  padding: 10px;
  text-align: center;
}
.send-button {
  background: #25D366;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
</style>
