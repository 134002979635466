<template>
  <section class="bg-light-pink p-20 text-gray-800">
    <div class="w-full max-w-6xl mx-auto">
      <h3 class="text-4xl font-bold mb-12 text-center text-pink-500">{{ $t('benefits.title') }}</h3>
      
      <!-- Grid de cartões de massagem -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div class="benefit-card" v-for="(benefit, index) in benefits" :key="index" data-aos="fade-up">
          <div class="icon bg-pink-500 text-white rounded-full p-4">
            <i :class="benefit.icon" class="text-4xl"></i>
          </div>
          <h4 class="text-2xl font-semibold mt-4">{{ $t(`benefits.items.${benefit.key}.title`) }}</h4>
          <p class="text-gray-700 mt-2">{{ $t(`benefits.items.${benefit.key}.description`) }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'MassageBenefits',
  data() {
    return {
      benefits: [
        { key: 'relaxing', icon: 'fas fa-spa' },
        { key: 'therapeutic', icon: 'fas fa-hand-holding-heart' },
        { key: 'sport', icon: 'fas fa-dumbbell' },
        { key: 'hotStones', icon: 'fas fa-stone' },
        { key: 'lymphatic', icon: 'fas fa-water' },
        { key: 'reflexology', icon: 'fas fa-foot' }
      ]
    };
  },
  mounted() {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true
    });
  }
}
</script>

<style scoped>
/* Fundo rosa claro */
.bg-light-pink {
  background-color: #fdf2f8;
  padding: 5rem 0;
  border-radius: 12px;
}

/* Título em rosa */
.text-pink-500 {
  color: #e09cb9;
}

/* Estilo dos cartões de benefícios */
.benefit-card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}
.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Ícones */
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
</style>
