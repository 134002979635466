<template>
  <div v-if="loading" class="loading-overlay">
    <div class="progress-container">
      <svg class="progress-circle" width="100" height="100" viewBox="0 0 100 100">
        <circle
          class="progress-background"
          cx="50"
          cy="50"
          r="45"
          stroke-width="8"
        ></circle>
        <circle
          class="progress-bar"
          cx="50"
          cy="50"
          r="45"
          stroke-width="8"
          :style="{ strokeDashoffset: circumference - (progress / 100) * circumference }"
        ></circle>
      </svg>
      <span class="progress-text">{{ progress }}%</span>
      <p class="loading-message">Por favor, aguarde enquanto preparamos tudo para você...</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      progress: 0,
      circumference: 2 * Math.PI * 45,
    };
  },
  mounted() {
    this.startLoading();
  },
  methods: {
    startLoading() {
      const interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 2; // Incrementa em 2% para um tempo total de aproximadamente 5 segundos
        } else {
          clearInterval(interval);
          this.loading = false;
        }
      }, 100); // Intervalo para uma atualização suave e contínua
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.progress-circle {
  transform: rotate(-90deg);
}

.progress-background {
  fill: none;
  stroke: #333;
  opacity: 0.2;
}

.progress-bar {
  fill: none;
  stroke: #ff69b4; /* Cor rosa */
  stroke-dasharray: 283;
  transition: stroke-dashoffset 0.2s;
}

.progress-text {
  position: absolute;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
  top: 50%;
  transform: translateY(-50%);
}

.loading-message {
  margin-top: 10px;
  font-size: 1em;
  color: #ffffff;
  text-align: center;
}
</style>
