var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"bg-primary text-accent p-10"},[_c('div',{staticClass:"container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left"},[_vm._m(0),_c('div',{staticClass:"contact-info space-y-4"},[_c('h4',{staticClass:"text-xl font-bold text-pink-500"},[_vm._v("Contato")]),_c('div',{staticClass:"flex items-center space-x-3"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'envelope']}}),_vm._m(1)],1),_c('div',{staticClass:"flex items-center space-x-3"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'phone']}}),_vm._m(2)],1)]),_vm._m(3),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-4"},[_c('h4',{staticClass:"text-3xl font-bold text-pink-500"},[_vm._v("Corpo & Mente")]),_c('p',{staticClass:"text-gray-300"},[_vm._v("Terapeuta de Massagem geral e de Relaxamento.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Email: "),_c('a',{staticClass:"text-accent underline hover:text-pink-300",attrs:{"href":"mailto:anacletomarina93@gmail.com"}},[_vm._v("anacletomarina93@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Telefone: "),_c('a',{staticClass:"text-accent underline hover:text-pink-300",attrs:{"href":"tel:+351918926359"}},[_vm._v("+351 918 926 359")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-4"},[_c('h4',{staticClass:"text-xl font-bold text-pink-500"},[_vm._v("Localização")]),_c('p',{staticClass:"text-gray-300"},[_vm._v("Rua doutor Francisco Sá Carneiro, n. 47, Peniche 2520")]),_c('div',{staticClass:"map-container rounded-lg overflow-hidden"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.5675654329054!2d-9.377453!3d39.356813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1f18b7f0b23ad7%3A0xc7a9bc243f2e9d7e!2sR.%20Dr.%20Francisco%20S%C3%A1%20Carneiro%2C%2047%2C%202520%20Peniche%2C%20Portugal!5e0!3m2!1sen!2sus!4v1699876543210!5m2!1sen!2sus","width":"100%","height":"150","allowfullscreen":"","loading":"lazy"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-y-4"},[_c('h4',{staticClass:"text-xl font-bold text-pink-500"},[_vm._v("Siga-nos")]),_c('div',{staticClass:"flex justify-center md:justify-start space-x-4"},[_c('a',{staticClass:"social-icon",attrs:{"href":"https://www.instagram.com","target":"_blank"}},[_c('img',{staticClass:"w-8 h-8",attrs:{"src":require("@/assets/icons/instagram.png"),"alt":"Instagram"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-8 text-gray-400"},[_c('p',[_vm._v("© 2024 Corpo & Mente. Todos os direitos reservados.")])])
}]

export { render, staticRenderFns }