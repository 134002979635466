<template>
  <footer class="bg-primary text-accent p-10">
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">

      <!-- Nome e Descrição -->
      <div class="space-y-4">
        <h4 class="text-3xl font-bold text-pink-500">Corpo & Mente</h4>
        <p class="text-gray-300">Terapeuta de Massagem geral e de Relaxamento.</p>
      </div>

      <!-- Informações de Contato -->
      <div class="contact-info space-y-4">
        <h4 class="text-xl font-bold text-pink-500">Contato</h4>
        
        <div class="flex items-center space-x-3">
          <font-awesome-icon :icon="['fas', 'envelope']" class="icon"/>
          <p>Email: <a href="mailto:anacletomarina93@gmail.com" class="text-accent underline hover:text-pink-300">anacletomarina93@gmail.com</a></p>
        </div>

        <div class="flex items-center space-x-3">
          <font-awesome-icon :icon="['fas', 'phone']" class="icon"/>
          <p>Telefone: <a href="tel:+351918926359" class="text-accent underline hover:text-pink-300">+351 918 926 359</a></p>
        </div>
      </div>

      <!-- Localização com Mapa Integrado -->
      <div class="space-y-4">
        <h4 class="text-xl font-bold text-pink-500">Localização</h4>
        <p class="text-gray-300">Rua doutor Francisco Sá Carneiro, n. 47, Peniche 2520</p>
        <div class="map-container rounded-lg overflow-hidden">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.5675654329054!2d-9.377453!3d39.356813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1f18b7f0b23ad7%3A0xc7a9bc243f2e9d7e!2sR.%20Dr.%20Francisco%20S%C3%A1%20Carneiro%2C%2047%2C%202520%20Peniche%2C%20Portugal!5e0!3m2!1sen!2sus!4v1699876543210!5m2!1sen!2sus"
            width="100%"
            height="150"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <!-- Redes Sociais -->
      <div class="space-y-4">
        <h4 class="text-xl font-bold text-pink-500">Siga-nos</h4>
        <div class="flex justify-center md:justify-start space-x-4">
          <a href="https://www.instagram.com" target="_blank" class="social-icon">
            <img src="@/assets/icons/instagram.png" alt="Instagram" class="w-8 h-8">
          </a>
          
        </div>
      </div>

    </div>
    
    <!-- Direitos Autorais -->
    <div class="text-center mt-8 text-gray-400">
      <p>&copy; 2024 Corpo & Mente. Todos os direitos reservados.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter'
};
</script>

<style scoped>
.bg-primary {
  background-color: #1f1f1f;
}

.text-accent {
  color: #ffffff;
}

.text-pink-500 {
  color: #e09cb9;
}

.social-icon img {
  filter: invert(100%);
  transition: transform 0.3s ease;
}
.social-icon:hover img {
  transform: scale(1.1);
}

.contact-info, .map-container {
  color: #ffffff;
}

.icon {
  color: #ffffff;
  font-size: 1.25rem;
  transition: color 0.3s ease;
}
.icon:hover {
  color: #cccccc;
}

/* Mapa */
.map-container {
  border: 2px solid #e09cb9;
  border-radius: 8px;
  overflow: hidden;
}

/* Links */
a:hover {
  text-decoration: none;
}

footer a {
  color: #ffffff;
}
</style>
