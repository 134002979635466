import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/styles/tailwind.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';
import VueI18n from 'vue-i18n';
import pt from './locales/pt.json';
import en from './locales/en.json';
import Cookies from 'js-cookie';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;
Vue.use(VueMeta);

new Vue({
  created() {
    // Verifica se o usuário já aceitou os cookies
    const consent = Cookies.get('cookiesAccepted');
    if (!consent) {
      // Exibe o banner de consentimento (você pode criar um modal ou componente para isso)
      this.showCookieBanner = true;
    }
  },
  data() {
    return {
      showCookieBanner: false
    };
  },
  methods: {
    acceptCookies() {
      Cookies.set('cookiesAccepted', 'true', { expires: 365 }); // Salva por 1 ano
      this.showCookieBanner = false;
    }
  },
  render: h => h(App)
}).$mount('#app');





// Importação das páginas
import HomePage from './components/HomePage.vue';
import SeoPage from './components/SeoPage.vue';
import TrafficPage from './components/TrafficPage.vue';
import WebsitesPage from './components/WebsitesPage.vue';

// Configuração do FontAwesome
library.add(faEnvelope, faPhone);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Configuração do VueRouter
Vue.use(VueRouter);
const routes = [
  { path: '/', component: HomePage },
  { path: '/seo', component: SeoPage },
  { path: '/traffic', component: TrafficPage },
  { path: '/websites', component: WebsitesPage }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

// Configuração do vue-i18n
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'pt', // idioma padrão
  messages: {
    pt,
    en
  }
});

// Configuração do AOS
AOS.init({
  duration: 800,
  easing: 'ease-in-out',
  once: true
});

// Criação da instância do Vue
new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app');
