<template>
  <section class="bg-light-gray p-20 text-gray-800">
    <div class="w-full max-w-6xl mx-auto">
      
      <!-- Título Principal da Seção -->
      <h2 class="text-5xl font-extrabold mb-16 text-center text-pink-500">{{ $t('pricing.title') }}</h2>

      <!-- Seção de Massagem Terapêutica -->
      <h3 class="text-4xl font-bold mb-12 text-center text-pink-500">{{ $t('pricing.therapeutic.title') }}</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
        <div class="price-card" v-for="(service, index) in therapeuticMassages" :key="index" data-aos="fade-up">
          <img :src="service.image" :alt="$t(`pricing.therapeutic.services.${service.key}.title`)" class="rounded-t-lg w-full h-40 object-cover">
          <div class="p-6">
            <h4 class="text-2xl font-semibold mb-2">{{ $t(`pricing.therapeutic.services.${service.key}.title`) }}</h4>
            <p class="text-gray-600 mb-2"><strong>{{ $t('pricing.area') }}:</strong> {{ $t(`pricing.therapeutic.services.${service.key}.area`) }}</p>
            <p class="text-gray-600 mb-2"><strong>{{ $t('pricing.duration') }}:</strong> {{ $t(`pricing.therapeutic.services.${service.key}.duration`) }}</p>
            <p class="text-gray-700 mt-2">{{ $t(`pricing.therapeutic.services.${service.key}.description`) }}</p>
            <p class="text-pink-500 font-bold text-2xl mt-4">{{ $t(`pricing.therapeutic.services.${service.key}.price`) }}</p>
          </div>
        </div>
      </div>

      <!-- Seção de Massagem de Relaxamento -->
      <h3 class="text-4xl font-bold mb-12 text-center text-pink-500">{{ $t('pricing.relaxation.title') }}</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div class="price-card" v-for="(service, index) in relaxationMassages" :key="index" data-aos="fade-up">
          <img :src="service.image" :alt="$t(`pricing.relaxation.services.${service.key}.title`)" class="rounded-t-lg w-full h-40 object-cover">
          <div class="p-6">
            <h4 class="text-2xl font-semibold mb-2">{{ $t(`pricing.relaxation.services.${service.key}.title`) }}</h4>
            <p class="text-gray-600 mb-2"><strong>{{ $t('pricing.area') }}:</strong> {{ $t(`pricing.relaxation.services.${service.key}.area`) }}</p>
            <p class="text-gray-600 mb-2"><strong>{{ $t('pricing.duration') }}:</strong> {{ $t(`pricing.relaxation.services.${service.key}.duration`) }}</p>
            <p class="text-gray-700 mt-2">{{ $t(`pricing.relaxation.services.${service.key}.description`) }}</p>
            <p class="text-pink-500 font-bold text-2xl mt-4">{{ $t(`pricing.relaxation.services.${service.key}.price`) }}</p>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import AOS from 'aos';
import 'aos/dist/aos.css';

import RelaxMassage from '@/assets/images/massage-relax.jpg';
//import TherapeuticMassage from '@/assets/images/massage-therapeutic.jpg';
import SportsMassage from '@/assets/images/massage-sports.jpg';
import HandFootMassage from '@/assets/images/massage-hands-feet.jpg';
import FaceScalpMassage from '@/assets/images/massage-face-scalp.jpg';
import FullTherapeuticMassage from '@/assets/images/massage-full-therapeutic.jpg';
import LocalTherapeuticMassage from '@/assets/images/massage-local-therapeutic.jpg';

export default {
  name: 'PricingSection',
  data() {
    return {
      therapeuticMassages: [
        { key: 'fullTherapeutic', image: FullTherapeuticMassage },
        { key: 'localTherapeutic', image: LocalTherapeuticMassage },
        //{ key: 'backTherapeutic', image: TherapeuticMassage }
      ],
      relaxationMassages: [
        { key: 'relaxing', image: RelaxMassage },
        { key: 'sports', image: SportsMassage },
        { key: 'sports1', image: SportsMassage },
        { key: 'handsFeet', image: HandFootMassage },
        { key: 'faceScalp', image: FaceScalpMassage }
      ]
    };
  },
  mounted() {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true
    });
  }
}

</script>

<style scoped>
/* Fundo cinza claro */
.bg-light-gray {
  background-color: #f9f9f9;
  padding: 5rem 0;
  border-radius: 12px;
}

/* Título em rosa */
.text-pink-500 {
  color: #e09cb9;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Suave sombra para destaque */
}

/* Preço em destaque */
.price-card p.text-pink-500 {
  color: #e09cb9;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  margin-top: 1rem;
  background: rgba(224, 156, 185, 0.15); /* Fundo suave rosa */
  padding: 0.5rem 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(224, 156, 185, 0.2);
}

/* Estilo dos cartões de preço */
.price-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.price-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Imagem do cartão */
.price-card img {
  transition: opacity 0.3s ease;
  height: 160px;
  width: 100%;
  object-fit: cover;
}
.price-card:hover img {
  opacity: 0.9;
}
</style>
