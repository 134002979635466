<template>
  <section class="hero-section flex items-center justify-center text-white relative">
    <div class="overlay absolute inset-0"></div>

    <div class="content-container relative z-10 max-w-4xl mx-auto text-center p-6 overflow-y-auto">
      <div class="flex flex-col items-center md:flex-row md:space-x-8 mb-10">
        <div class="photo-container">
          <img 
            src="@/assets/images/minha-foto.jpg" 
            :alt="$t('hero.title')" 
            class="profile-photo"
          >
        </div>
        
        <div class="text-center md:text-left mt-6 md:mt-0">
          <h1 class="text-3xl md:text-4xl font-bold mb-3">{{ $t('hero.title') }}</h1>
          <p class="text-lg md:text-xl leading-relaxed mb-4">
            {{ $t('hero.description') }}
          </p>
          <button @click="showModal = true" class="btn-primary">{{ $t('hero.viewCertificate') }}</button>
        </div>
      </div>

      <div v-if="showModal" class="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div class="modal bg-white p-8 rounded-lg relative max-w-lg w-full">
          <button @click="showModal = false" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-xl">&times;</button>
          <h2 class="text-xl font-bold mb-4">{{ $t('hero.certificateTitle') }}</h2>
          <img src="@/assets/images/certificado.jpg" :alt="$t('hero.certificateTitle')" class="w-full rounded">
        </div>
      </div>

      <div class="skills-list text-left mt-8">
        <h2 class="text-2xl font-semibold mb-4">{{ $t('hero.skillsTitle') }}</h2>
        <ul class="list-disc list-inside space-y-2">
          <li v-for="(skill, index) in $t('hero.skills')" :key="index">{{ skill }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  data() {
    return {
      showModal: false
    };
  }
}
</script>

<style scoped>
/* Fundo com efeito parallax */
.hero-section {
  height: 100vh;
  background-image: url('@/assets/images/fundo-parallax.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.content-container {
  max-height: 85vh;
  overflow-y: auto;
}

/* Estilo da foto com borda decorativa e sombra */
.photo-container {
  width: 100%;
  max-width: 300px;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}
.photo-container:hover {
  transform: scale(1.05);
}

.profile-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}

/* Estilo do botão primário */
.btn-primary {
  background-color: #e09cb9;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.btn-primary:hover {
  background-color: #c07a98;
}

/* Estilos do modal */
.modal-overlay {
  z-index: 50;
}
.modal {
  max-width: 500px;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}

.skills-list h2 {
  color: #e09cb9;
}

p, ul li {
  color: #f1f1f1;
}

</style>
