<template>
    <section class="bg-light-gray p-20 text-gray-800">
      <div class="w-full max-w-6xl mx-auto text-center">
        <h3 class="text-4xl font-bold mb-12 text-pink-500">Recomendações de Clientes</h3>
    
        <!-- Grid de Recomendações -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          
          <div class="review-card" v-for="(review, index) in reviews" :key="index" data-aos="fade-up">
            <div class="flex items-center mb-4">
              <div class="review-avatar">
                <span class="initials">{{ review.name[0] }}</span>
              </div>
              <div class="ml-4 text-left">
                <h4 class="text-xl font-semibold">{{ review.name }}</h4>
                <p class="text-gray-500 text-sm">{{ review.date }}</p>
              </div>
            </div>
            
            <p class="text-gray-700 mb-4">{{ review.comment }}</p>
            
            <div class="text-yellow-500 mt-2">
              <span v-for="star in 5" :key="star" class="fas fa-star"></span>
            </div>
            
            <a :href="review.link" target="_blank" class="text-blue-500 underline mt-4 block">Veja no Google Reviews</a>
          </div>
          
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import AOS from 'aos';
  import 'aos/dist/aos.css';
  
  export default {
    name: 'CustomerReviews',
    data() {
      return {
        reviews: [
          {
            name: 'Inês Façanha',
            date: '04/07/2024',
            comment: 'A Marina é uma ótima massagista, fiquei super satisfeita, tem umas mãos milagrosas recomendo bastante',
            link: 'https://www.google.com/search?client=ubuntu-sn&hs=wxZ&sca_esv=a07f0383584960a3&channel=fs&sxsrf=ADLYWILP-Esx9kaOqyXB_9n5LTWmQRnnzw:1731335104769&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dwQAXYZZ0VLumGp-nnbZQxvGiQ2SWGF6BacS_dPA7GQphWR7-TzPM010wcLN0JKn7dJhAZIS8eoKqZxhCQCQm92ip5YI7F64_dR8sJ_Cbfvf7uZoWjEeW_AcvDQboWo-5zAIYpsiX8TkDuNwhDBhansLrPPLHRDK7kuW1hs65k-qgE3a5ywYM9MU-R4H28y99b-yruCmOmqJgT6EE1SJCqv2-QZNWYzNC1tB5Ta-Zk_Pi1Qf2&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7izK4eT4pdUBul9sRYFj-mGmHjnB5N_RnvadrBKNF0FsCSAdEtZTkwNebTnupmnm9MAg80KwW7lhdEUvESwKS2R0WrYe&q=Corpo+%26+Mente+Reviews&hl=en-PT&sa=X&ved=2ahUKEwjgoqDvvdSJAxUsSaQEHSSPM54Q_4MLegQIRxAN&biw=1280&bih=1213&dpr=1'
          },
          {
            name: 'Tânia Grifo',
            date: '15/07/2024',
            comment: 'Fui fazer uma massagem com a Marina e recomendo se forem não se vão arrepender, melhor massagista que há',
            link: 'https://www.google.com/search?client=ubuntu-sn&hs=wxZ&sca_esv=a07f0383584960a3&channel=fs&sxsrf=ADLYWILP-Esx9kaOqyXB_9n5LTWmQRnnzw:1731335104769&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dwQAXYZZ0VLumGp-nnbZQxvGiQ2SWGF6BacS_dPA7GQphWR7-TzPM010wcLN0JKn7dJhAZIS8eoKqZxhCQCQm92ip5YI7F64_dR8sJ_Cbfvf7uZoWjEeW_AcvDQboWo-5zAIYpsiX8TkDuNwhDBhansLrPPLHRDK7kuW1hs65k-qgE3a5ywYM9MU-R4H28y99b-yruCmOmqJgT6EE1SJCqv2-QZNWYzNC1tB5Ta-Zk_Pi1Qf2&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7izK4eT4pdUBul9sRYFj-mGmHjnB5N_RnvadrBKNF0FsCSAdEtZTkwNebTnupmnm9MAg80KwW7lhdEUvESwKS2R0WrYe&q=Corpo+%26+Mente+Reviews&hl=en-PT&sa=X&ved=2ahUKEwjgoqDvvdSJAxUsSaQEHSSPM54Q_4MLegQIRxAN&biw=1280&bih=1213&dpr=1'
          },
          {
            name: 'Yohann Beaure',
            date: '10/09/2024',
            comment: "My mother and I benefited from Marina's magical hands. After a week of traveling and driving, it relieved all our pain. The massage parlor is very clean, easy to access, the essential oils used work miracles. Thank you thank you thank you.",
            link: 'https://www.google.com/search?client=ubuntu-sn&hs=wxZ&sca_esv=a07f0383584960a3&channel=fs&sxsrf=ADLYWILP-Esx9kaOqyXB_9n5LTWmQRnnzw:1731335104769&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dwQAXYZZ0VLumGp-nnbZQxvGiQ2SWGF6BacS_dPA7GQphWR7-TzPM010wcLN0JKn7dJhAZIS8eoKqZxhCQCQm92ip5YI7F64_dR8sJ_Cbfvf7uZoWjEeW_AcvDQboWo-5zAIYpsiX8TkDuNwhDBhansLrPPLHRDK7kuW1hs65k-qgE3a5ywYM9MU-R4H28y99b-yruCmOmqJgT6EE1SJCqv2-QZNWYzNC1tB5Ta-Zk_Pi1Qf2&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7izK4eT4pdUBul9sRYFj-mGmHjnB5N_RnvadrBKNF0FsCSAdEtZTkwNebTnupmnm9MAg80KwW7lhdEUvESwKS2R0WrYe&q=Corpo+%26+Mente+Reviews&hl=en-PT&sa=X&ved=2ahUKEwjgoqDvvdSJAxUsSaQEHSSPM54Q_4MLegQIRxAN&biw=1280&bih=1213&dpr=1'
          },
          {
            name: 'Roman Bending',
            date: '20/08/2024',
            comment: "I came to Peniche for the first time to surf and after a few days I desperately needed a massage. I was lucky enough to meet Marina. She is an absolute professional! I felt absolutely in safe hands with her. Sensitive where it should be and strong where tension needs to be released.",
            link: 'https://www.google.com/search?client=ubuntu-sn&hs=wxZ&sca_esv=a07f0383584960a3&channel=fs&sxsrf=ADLYWILP-Esx9kaOqyXB_9n5LTWmQRnnzw:1731335104769&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dwQAXYZZ0VLumGp-nnbZQxvGiQ2SWGF6BacS_dPA7GQphWR7-TzPM010wcLN0JKn7dJhAZIS8eoKqZxhCQCQm92ip5YI7F64_dR8sJ_Cbfvf7uZoWjEeW_AcvDQboWo-5zAIYpsiX8TkDuNwhDBhansLrPPLHRDK7kuW1hs65k-qgE3a5ywYM9MU-R4H28y99b-yruCmOmqJgT6EE1SJCqv2-QZNWYzNC1tB5Ta-Zk_Pi1Qf2&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7izK4eT4pdUBul9sRYFj-mGmHjnB5N_RnvadrBKNF0FsCSAdEtZTkwNebTnupmnm9MAg80KwW7lhdEUvESwKS2R0WrYe&q=Corpo+%26+Mente+Reviews&hl=en-PT&sa=X&ved=2ahUKEwjgoqDvvdSJAxUsSaQEHSSPM54Q_4MLegQIRxAN&biw=1280&bih=1213&dpr=1'
          },
        ]
      };
    },
    mounted() {
      AOS.init({
        duration: 800,
        easing: 'ease-in-out',
        once: true
      });
    }
  }
  </script>
  
  <style scoped>
  /* Fundo e título da seção */
  .bg-light-gray {
    background-color: #f9f9f9;
    padding: 5rem 0;
    border-radius: 12px;
  }
  
  .text-pink-500 {
    color: #e09cb9;
  }
  
  /* Estilo do cartão de avaliação */
  .review-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    position: relative;
    overflow: hidden;
  }
  .review-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  /* Avatar com as iniciais */
  .review-avatar {
    background-color: #e09cb9;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #ffffff;
  }
  .initials {
    font-weight: bold;
  }
  
  /* Estilo das estrelas */
  .fas.fa-star {
    color: #ffcc00;
    transition: transform 0.3s ease;
  }
  .review-card:hover .fas.fa-star {
    transform: scale(1.1);
  }
  
  /* Link do Google Reviews */
  a {
    text-decoration: none;
  }
  a:hover {
    color: #e09cb9;
  }
  </style>
  